import {
	ComponentRef,
	Directive,
	Injector,
	OnChanges,
	OnInit,
	SimpleChanges,
	ViewContainerRef,
	ɵcreateInjector,
} from '@angular/core';

import { loadModuleAsync } from '../../remote-app-loader';

@Directive({ selector: '[crmTopicGridLoader]', standalone: true })
export class CrmTopicGridLoaderDirective implements OnInit, OnChanges {
	private _componentRef: ComponentRef<any>;

	constructor(
		private _injector: Injector,
		private _viewContainerRef: ViewContainerRef
	) {}

	ngOnInit(): void {
		void this.initComponent();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (
			changes &&
			Object.keys(changes).length > 0 &&
			Object.keys(changes).every(key => !changes[key].firstChange)
		) {
			this.setComponentInputs();
		}
	}

	private async initComponent() {
		await loadModuleAsync('crm', 'CrmTopicGridModule').then(module => {
			const TopicListContainerComponent = module.components['TopicListContainerComponent'];

			this._componentRef = this._viewContainerRef.createComponent<any>(
				TopicListContainerComponent,
				{ injector: ɵcreateInjector(module, this._injector) }
			);

			this.setComponentInputs();

			this._componentRef.changeDetectorRef.markForCheck();
		});
	}

	private setComponentInputs() {
		if (!this._componentRef) {
			return;
		}
		// TODO: Bind component input here
	}
}
