import {
	ComponentRef,
	Directive,
	EventEmitter,
	Injector,
	OnDestroy,
	OnInit,
	Output,
	ViewContainerRef,
	ɵcreateInjector,
} from '@angular/core';

import { Subject, takeUntil } from 'rxjs';

import { loadModuleAsync } from '../../remote-app-loader';

@Directive({ selector: '[projectViewSettingComponentLoader]', standalone: true })
export class ProjectViewSettingLoaderDirective implements OnInit, OnDestroy {
	@Output() onSaveOptionSelected = new EventEmitter<void>();
	@Output() onCreateOptionSelected = new EventEmitter<void>();
	@Output() onRestoreOptionSelected = new EventEmitter<void>();

	private _componentRef: ComponentRef<any>;
	private _destroy$ = new Subject<void>();

	constructor(
		private _injector: Injector,
		private _viewContainerRef: ViewContainerRef
	) {}

	ngOnInit(): void {
		void this.initComponent();
	}

	ngOnDestroy(): void {
		this._destroy$.next();
	}

	private async initComponent() {
		await loadModuleAsync('work', 'WorkRemoteProjectViewSettingModule').then(module => {
			const ProjectViewSettingViewComponent =
				module.components['ProjectViewSettingViewComponent'];

			this._componentRef = this._viewContainerRef.createComponent<any>(
				ProjectViewSettingViewComponent,
				{ injector: ɵcreateInjector(module, this._injector) }
			);

			this._componentRef.instance.onSaveOptionSelected
				.pipe(takeUntil(this._destroy$))
				.subscribe((sort: never) => this.onSaveOptionSelected.emit(sort));
			this._componentRef.instance.onCreateOptionSelected
				.pipe(takeUntil(this._destroy$))
				.subscribe(() => this.onCreateOptionSelected.emit());
			this._componentRef.instance.onRestoreOptionSelected
				.pipe(takeUntil(this._destroy$))
				.subscribe(() => this.onRestoreOptionSelected.emit());

			this._componentRef.changeDetectorRef.detectChanges();
		});
	}
}
