import {
	ComponentRef,
	Directive,
	EventEmitter,
	Injector,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewContainerRef,
} from '@angular/core';

import { Subject, takeUntil } from 'rxjs';

import { loadModuleAsync } from '../../remote-app-loader';

export interface IRecordDetailPanelComponent {
	showSubmitterView: boolean;
	showPreNextButton: boolean;
	fullAppScreen: boolean;
	isSubscribeRoute: boolean;
	detectClickOutsideAnElement: boolean;
	embeddedComment: any;
	formResult: any;
	index: number;
	totalRecords: number;
	selectedTabType: string | undefined;

	onIndexChanged: EventEmitter<any>;
	onRecordNameUpdated: EventEmitter<any>;
	onClosed: EventEmitter<void>;
}

@Directive({
	standalone: true,
	selector: '[customAppRecordDetailPanelLoaderDirective]',
	exportAs: 'RecordDetailPanelLoaderDirective',
})
export class CustomAppRecordDetailPanelLoaderDirective
	implements OnInit, OnChanges, OnDestroy, IRecordDetailPanelComponent
{
	@Input() showSubmitterView: boolean;
	@Input() fullAppScreen: boolean;
	@Input() isSubscribeRoute: boolean;
	@Input() formResult: any;
	@Input() index: number;
	@Input() totalRecords: number;
	@Input() showPreNextButton: boolean;
	@Input() detectClickOutsideAnElement: boolean;
	@Input() embeddedComment: any;
	@Input() selectedTabType: string | undefined;
	@Input() showToggleSectionButton = true;
	@Output() onIndexChanged = new EventEmitter<any>();
	@Output() onRecordNameUpdated = new EventEmitter<any>();
	@Output() onClosed = new EventEmitter<void>();

	private _componentRef: ComponentRef<IRecordDetailPanelComponent>;
	private _destroy$ = new Subject<void>();

	constructor(
		private _injector: Injector,
		private _viewRef: ViewContainerRef
	) {}

	ngOnInit(): void {
		void this.initComponent();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (
			changes &&
			Object.keys(changes).length > 0 &&
			Object.keys(changes).every(key => !changes[key].firstChange)
		) {
			this.setComponentInputs();
		}
	}

	ngOnDestroy(): void {
		this._destroy$.next();
	}

	private async initComponent() {
		const component = await loadModuleAsync('customApp', 'RecordDetailPanelRemoteComponent');
		this._componentRef = this._viewRef.createComponent<IRecordDetailPanelComponent>(component, {
			injector: this._injector,
		});

		this.setComponentInputs();
		// Subscribe output events
		this._componentRef.instance.onClosed.pipe(takeUntil(this._destroy$)).subscribe(() => {
			this.onClosed.emit();
		});

		this._componentRef.instance.onRecordNameUpdated
			.pipe(takeUntil(this._destroy$))
			.subscribe(() => {
				this.onRecordNameUpdated.emit();
			});

		this._componentRef.instance.onIndexChanged.pipe(takeUntil(this._destroy$)).subscribe(() => {
			this.onIndexChanged.emit();
		});

		this._componentRef.changeDetectorRef.markForCheck();
	}

	private setComponentInputs() {
		if (!this._componentRef) {
			return;
		}

		this._componentRef.setInput('showSubmitterView', this.showSubmitterView);
		this._componentRef.setInput('showToggleSectionButton', this.showToggleSectionButton);
		this._componentRef.setInput('fullAppScreen', this.fullAppScreen);
		this._componentRef.setInput('isSubscribeRoute', this.isSubscribeRoute);
		this._componentRef.setInput('formResult', this.formResult);
		this._componentRef.setInput('index', this.index);
		this._componentRef.setInput('totalRecords', this.totalRecords);
		this._componentRef.setInput('showPreNextButton', this.showPreNextButton);
		this._componentRef.setInput('embeddedComment', this.embeddedComment);
		this._componentRef.setInput('selectedTabType', this.selectedTabType);
		this._componentRef.setInput(
			'detectClickOutsideAnElement',
			this.detectClickOutsideAnElement
		);
	}
}
