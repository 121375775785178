import {
	ComponentRef,
	Directive,
	EventEmitter,
	Injector,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewContainerRef,
} from '@angular/core';

import { Subject, takeUntil } from 'rxjs';

import { loadModuleAsync } from '../../remote-app-loader';

export interface IRecordAppearanceContainerComponent {
	appId: number;
	projectId: number;
	formId: number;
	isFormDirty: () => boolean;
	handleCancel: () => void;

	onSubmitted: EventEmitter<void>;
	onCancel: EventEmitter<void>;
}

@Directive({
	standalone: true,
	selector: '[customAppRecordAppearanceLoaderDirective]',
	exportAs: 'RecordAppearanceLoaderDirective',
})
export class CustomAppRecordAppearanceLoaderDirective
	implements OnInit, OnChanges, OnDestroy, IRecordAppearanceContainerComponent
{
	@Input() appId: number;
	@Input() projectId: number;
	@Input() formId: number;

	@Output() onSubmitted = new EventEmitter<void>();
	@Output() onCancel = new EventEmitter<void>();

	private _componentRef: ComponentRef<IRecordAppearanceContainerComponent>;
	private _destroy$ = new Subject<void>();

	constructor(
		private _injector: Injector,
		private _viewRef: ViewContainerRef
	) {}

	ngOnInit(): void {
		void this.initComponent();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (
			changes &&
			Object.keys(changes).length > 0 &&
			Object.keys(changes).every(key => !changes[key].firstChange)
		) {
			this.setComponentInputs();
		}
	}

	ngOnDestroy(): void {
		this._destroy$.next();
	}

	public isFormDirty() {
		return this._componentRef?.instance?.isFormDirty();
	}

	public handleCancel() {
		return this._componentRef?.instance?.handleCancel();
	}

	private async initComponent() {
		const component = await loadModuleAsync(
			'customApp',
			'CustomAppRecordAppearanceRemoteComponent'
		);
		this._componentRef = this._viewRef.createComponent<IRecordAppearanceContainerComponent>(
			component,
			{ injector: this._injector }
		);

		this.setComponentInputs();
		// Subscribe output events
		this._componentRef.instance.onSubmitted.pipe(takeUntil(this._destroy$)).subscribe(() => {
			this.onSubmitted.emit();
		});

		this._componentRef.instance.onCancel.pipe(takeUntil(this._destroy$)).subscribe(() => {
			this.onCancel.emit();
		});

		this._componentRef.changeDetectorRef.markForCheck();
	}

	private setComponentInputs() {
		if (!this._componentRef) {
			return;
		}
		// Set input data
		this._componentRef.setInput('appId', this.appId);
		this._componentRef.setInput('projectId', this.projectId);
		this._componentRef.setInput('formId', this.formId);
	}
}
