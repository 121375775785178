import {
	ComponentRef,
	Directive,
	EventEmitter,
	Injector,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewContainerRef,
	ɵcreateInjector,
} from '@angular/core';

import { Subject, takeUntil } from 'rxjs';

import { loadModuleAsync } from '../../remote-app-loader';

export interface CleeksyHelpAssistantContainerComponent {
	visible: boolean;
	target: string | Element;
	visibleChange: EventEmitter<boolean>;
}

@Directive({ selector: '[helpCenterComponentLoader]', standalone: true })
export class HelpCenterPopoverLoaderDirective implements OnInit, OnChanges, OnDestroy {
	@Input() isChatBotMode = false;
	@Input() isHideAssistantBack = false;
	@Input() target: string | Element;
	@Input() visible: boolean;

	@Output() onClose = new EventEmitter<void>();

	private _componentRef: ComponentRef<any>;
	private _destroy$ = new Subject<void>();

	constructor(
		private _injector: Injector,
		private _viewContainerRef: ViewContainerRef
	) {}

	ngOnInit(): void {
		void this.initComponent();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (
			changes &&
			Object.keys(changes).length > 0 &&
			Object.keys(changes).every(key => !changes[key].firstChange)
		) {
			this.setComponentInputs();
		}
	}

	ngOnDestroy(): void {
		this._destroy$.next();
	}

	private async initComponent() {
		await loadModuleAsync('helpCenter', 'GuidanceCenterRemoteModule').then(m => {
			// Load CleeksyHelpAssistantContainerComponent
			const CleeksyHelpAssistantContainerComponent =
				m.components['CleeksyHelpAssistantContainerComponent'];

			this._componentRef =
				this._viewContainerRef.createComponent<CleeksyHelpAssistantContainerComponent>(
					CleeksyHelpAssistantContainerComponent,
					{
						injector: ɵcreateInjector(m, this._injector),
					}
				);

			this._componentRef.location.nativeElement.style.position = 'absolute';

			this.setComponentInputs();
			// Subscribe close event;
			this._componentRef.instance.visibleChange
				.pipe(takeUntil(this._destroy$))
				.subscribe((visible: boolean) => {
					this.visible = visible;
					if (visible) {
						return;
					}
					this.onClose.emit();
				});

			this._componentRef.changeDetectorRef.markForCheck();
		});
	}

	private setComponentInputs() {
		if (!this._componentRef) {
			return;
		}
		// Set input data
		this._componentRef.setInput('isHideAssistantBack', this.isHideAssistantBack);
		this._componentRef.setInput('isChatBotMode', this.isChatBotMode);
		this._componentRef.setInput('visible', this.visible);
		this._componentRef.setInput('target', this.target);
	}
}
