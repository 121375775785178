import {
	ComponentRef,
	Directive,
	EventEmitter,
	Injector,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewContainerRef,
} from '@angular/core';

import { Subject, takeUntil } from 'rxjs';

import { loadModuleAsync } from '../../remote-app-loader';

export interface ICustomAppFormSubmitContainerComponent {
	visible: boolean;
	form: unknown;
	onlyFormVisible: boolean;
	canSubmit: boolean;
	isFormInProcess: boolean;
	isFeedbackForm: boolean;
	isFromCommunity: boolean;
	ticketFormInfo: {
		title: string;
		message: string;
		userName: string;
	};
	projectId: number | undefined;
	readonly: boolean;
	taskId: number;
	currentStepId: number;
	showAnonymousNotice: boolean;
	showPolicy: boolean;

	visibleChange: EventEmitter<boolean>;
	onFormSubmitted: EventEmitter<unknown>;
	onClosed: EventEmitter<unknown>;
	onUpdateResult: EventEmitter<any>;
	onPlaceHolderBtnClicked: EventEmitter<unknown>;
	onExternalPublicFormLoaded: EventEmitter<boolean>;
}

@Directive({ selector: '[customAppFormSubmitLoaderDirective]', standalone: true })
export class CustomAppFormSubmitLoaderDirective
	implements OnInit, OnChanges, OnDestroy, ICustomAppFormSubmitContainerComponent
{
	@Input() form: unknown;
	@Input() visible: boolean;
	@Input() onlyFormVisible = false;
	@Input() canSubmit = true;
	@Input() isFormInProcess: boolean;
	@Input() isFeedbackForm: boolean;
	@Input() isFromCommunity: boolean;
	@Input() ticketFormInfo: {
		title: string;
		message: string;
		userName: string;
	};
	@Input() projectId: number | undefined;
	@Input() readonly = false;
	@Input() taskId: number;
	@Input() currentStepId: number;
	@Input() showAnonymousNotice = false;
	@Input() showPolicy = false;

	@Output() visibleChange = new EventEmitter<boolean>();
	@Output() onFormSubmitted = new EventEmitter<any>();
	@Output() onClosed = new EventEmitter<any>();
	@Output() onUpdateResult = new EventEmitter<any>();
	@Output() onPlaceHolderBtnClicked = new EventEmitter();
	@Output() onExternalPublicFormLoaded = new EventEmitter<boolean>();
	private _componentRef: ComponentRef<any>;
	private _destroy$ = new Subject<void>();

	constructor(
		private _injector: Injector,
		private _viewContainerRef: ViewContainerRef
	) {}

	ngOnInit(): void {
		void this.initComponent();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (
			changes &&
			Object.keys(changes).length > 0 &&
			Object.keys(changes).every(key => !changes[key].firstChange)
		) {
			this.setComponentInputs();
		}
	}

	ngOnDestroy(): void {
		this._destroy$.next();
	}

	private async initComponent() {
		const component = await loadModuleAsync('customApp', 'CustomAppFormSubmitRemoteModule');
		this._componentRef =
			this._viewContainerRef.createComponent<ICustomAppFormSubmitContainerComponent>(
				component,
				{
					injector: this._injector,
				}
			);

		this._componentRef.location.nativeElement.style.position = 'absolute';

		this.setComponentInputs();
		// Subscribe close event;
		this._componentRef.instance.visibleChange
			.pipe(takeUntil(this._destroy$))
			.subscribe((visible: boolean) => {
				this.visible = visible;
				this.visibleChange.emit(visible);
			});

		this._componentRef.instance.onFormSubmitted
			.pipe(takeUntil(this._destroy$))
			.subscribe((value: unknown) => this.onFormSubmitted.emit(value));

		this._componentRef.instance.onClosed
			.pipe(takeUntil(this._destroy$))
			.subscribe((value: unknown) => this.onClosed.emit(value));

		this._componentRef.instance.onUpdateResult
			.pipe(takeUntil(this._destroy$))
			.subscribe((value: unknown) => this.onUpdateResult.emit(value));

		this._componentRef.instance.onPlaceHolderBtnClicked
			.pipe(takeUntil(this._destroy$))
			.subscribe((value: unknown) => this.onPlaceHolderBtnClicked.emit(value));

		this._componentRef.instance.onExternalPublicFormLoaded
			.pipe(takeUntil(this._destroy$))
			.subscribe((value: boolean) => this.onExternalPublicFormLoaded.emit(value));

		this._componentRef.changeDetectorRef.markForCheck();
	}

	private setComponentInputs() {
		if (!this._componentRef) {
			return;
		}
		// Set input data
		this._componentRef.setInput('onlyFormVisible', this.onlyFormVisible);
		this._componentRef.setInput('canSubmit', this.canSubmit);
		this._componentRef.setInput('isFormInProcess', this.isFormInProcess);
		this._componentRef.setInput('isFeedbackForm', this.isFeedbackForm);
		this._componentRef.setInput('isFromCommunity', this.isFromCommunity);
		this._componentRef.setInput('showPolicy', this.showPolicy);
		this._componentRef.setInput('showAnonymousNotice', this.showAnonymousNotice);
		this._componentRef.setInput('readonly', this.readonly);
		this._componentRef.setInput('visible', this.visible);
		this._componentRef.setInput('projectId', this.projectId);
		this._componentRef.setInput('taskId', this.taskId);
		this._componentRef.setInput('currentStepId', this.currentStepId);
		this._componentRef.setInput('ticketFormInfo', this.ticketFormInfo);
		this._componentRef.setInput('form', this.form);
	}
}
