import {
	ComponentRef,
	Directive,
	EventEmitter,
	Injector,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewContainerRef,
	ɵcreateInjector,
} from '@angular/core';

import { Subject, takeUntil } from 'rxjs';

import { loadModuleAsync } from '../../remote-app-loader';

@Directive({ selector: '[workCalendarViewComponentLoader]', standalone: true })
export class WorkCalendarViewLoaderDirective implements OnInit, OnChanges, OnDestroy {
	@Input() projectId: number;
	@Input() canAddTask = false;
	@Input() isShowOnMyWorkspace = false;

	@Output() onTaskClicked = new EventEmitter<any>();
	private _componentRef: ComponentRef<any>;

	private _destroy$ = new Subject<void>();

	constructor(
		private _injector: Injector,
		private _viewContainerRef: ViewContainerRef
	) {}

	ngOnInit(): void {
		void this.initComponent();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (
			changes &&
			Object.keys(changes).length > 0 &&
			Object.keys(changes).every(key => !changes[key].firstChange)
		) {
			this.setComponentInputs();
		}
	}

	ngOnDestroy(): void {
		this._destroy$.next();
	}

	private async initComponent() {
		await loadModuleAsync('work', 'WorkRemoteProjectCalendarViewModule').then(module => {
			const CalendarSchedulerContainerComponent =
				module.components['CalendarSchedulerContainerComponent'];

			this._componentRef = this._viewContainerRef.createComponent<any>(
				CalendarSchedulerContainerComponent,
				{ injector: ɵcreateInjector(module, this._injector) }
			);

			this.setComponentInputs();

			this._componentRef.instance.onTaskClicked
				.pipe(takeUntil(this._destroy$))
				.subscribe((task: never) => this.onTaskClicked.emit(task));

			this._componentRef.changeDetectorRef.markForCheck();
		});
	}

	private setComponentInputs() {
		if (!this._componentRef) {
			return;
		}
		this._componentRef.setInput('projectId', this.projectId);
		this._componentRef.setInput('canAddTask', this.canAddTask);
		this._componentRef.setInput('isShowOnMyWorkspace', this.isShowOnMyWorkspace);
	}
}
