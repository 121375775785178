import { loadRemoteModule, setRemoteDefinitions } from '@nx/angular/mf';

import { ModuleIdentity, ServiceIdentity } from './models/remote-identity';
import { getConfiguration } from './remote-load-option';
import { REMOTE_HOSTED_CONFIGURATIONS, REMOTE_LOCAL_CONFIGURATIONS } from './configurations';

function getAppVersionQuery(): string {
	const CLEEKSY_VERSION_KEY = 'CLEEKSY_VERSION';

	let version: string;
	// Only enable version caching for prod environment
	if (window.location.host === 'app.cleeksy.com') {
		version = localStorage.getItem(CLEEKSY_VERSION_KEY) || `${Date.now()}`;
	} else {
		version = `${Date.now()}`;
	}
	// NOTE: End with .mjs or .js to force @nx don't cache the module entry files
	return `${version}.mjs`;
}

/**
 * This function removes the last part of a given URL.
 * It splits the URL by '/', removes the last element from the resulting array, and then joins the array back into a string with '/'.
 *
 * @param {string} url - The URL to remove the last part from.
 * @returns {string} - The URL with the last part removed.
 *
 * NOTE: Remove this function when update remote entry in the mfe configuration
 */
function removeLastPartOf(url: string): string {
	const paths = url.split('/');
	paths.pop();
	return paths.join('/');
}

/**
 * Load remote url definitions based on the current host.
 */
export function loadRemoteDefinitions() {
	// Determine which configurations to use based on the host of the current window location
	const configurations = window.location.host.startsWith('localhost')
		? REMOTE_LOCAL_CONFIGURATIONS
		: REMOTE_HOSTED_CONFIGURATIONS;

	const remoteEntryFileName = 'remoteEntry.mjs';
	// Create a definitions object where each key is a configuration key and the value is the entry of the configuration
	// with the last part of the URL removed. If the configuration doesn't have an entry, an empty string is used as the value.
	const definitions: Record<string, string> = {};
	Object.keys(configurations).map(key => {
		definitions[key] = configurations[key].entry
			? removeLastPartOf(configurations[key].entry)
			: '';

		definitions[key] += `/${remoteEntryFileName}?v=${getAppVersionQuery()}`;
	});

	// Set the remote definitions with the created definitions object
	setRemoteDefinitions(definitions);
}

export async function loadModuleAsync(service: ServiceIdentity, module: ModuleIdentity) {
	try {
		return await loadRemoteModule(service, `./${module}`).then(m => m[module]);
	} catch (error: unknown) {
		console.log(`MFE --- loadModuleAsync: `, error);
		throw error as Error;
	}
}

export async function preInitRemoteModules() {
	try {
		const preLoadServices: ServiceIdentity[] = [
			'work',
			'customApp',
			'workspace',
			'messenger',
			'analyticsHub',
		];

		const configurations = preLoadServices.map(service => {
			const configuration = getConfiguration(service);
			return { service: service, exposes: configuration.exposes };
		});

		const requests = configurations
			.flatMap(config =>
				config.exposes.map(module => {
					return { service: config.service, expose: module };
				})
			)
			.map(config => loadModuleAsync(config.service, config.expose));

		await Promise.allSettled(requests);
	} catch (error: unknown) {
		console.log(`MFE --- preInitRemoteModules: `, error);
	}
}
