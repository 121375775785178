import {
	ComponentRef,
	Directive,
	EventEmitter,
	Injector,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewContainerRef,
	ɵcreateInjector,
} from '@angular/core';

import { Subject, takeUntil } from 'rxjs';

import { loadModuleAsync } from '../../remote-app-loader';

@Directive({ selector: '[projectBoardFilterComponentLoader]', standalone: true })
export class ProjectBoardFilterComponentLoaderDirective implements OnInit, OnChanges, OnDestroy {
	@Input() projectId: number;
	@Input() viewMode: any;
	@Input() projectMembers: any;
	@Input() viewFilter: any;
	@Input() canEditSprint = false;
	@Input() canAddSprint = false;
	@Input() allowManagerSprint = false;
	@Input() hasJoiningProjectRequest: boolean | null;
	@Input() filterMemberEnabled: boolean;
	@Input() canViewGenerateSetting: boolean;
	@Input() canEditGenerateSetting: boolean;
	@Input() showProjectViewMode = false;
	@Input() sortEnabled = true;
	@Input() viewSorts: any[] = [];

	@Output() filterKanbanClicked = new EventEmitter<any>();
	@Output() onSortClicked = new EventEmitter<any[]>();
	@Output() onNewViewCreated = new EventEmitter<void>();
	@Output() onViewRestored = new EventEmitter<void>();

	private _componentRef: ComponentRef<any>;
	private _destroy$ = new Subject<void>();

	constructor(
		private _injector: Injector,
		private _viewContainerRef: ViewContainerRef
	) {}

	ngOnInit(): void {
		void this.initComponent();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (
			changes &&
			Object.keys(changes).length > 0 &&
			Object.keys(changes).every(key => !changes[key].firstChange)
		) {
			this.setComponentInputs();
		}
	}

	ngOnDestroy(): void {
		this._destroy$.next();
	}

	private async initComponent() {
		await loadModuleAsync('work', 'WorkRemoteProjectBoardFilterModule').then(module => {
			const ProjectBoardFilterContainerComponent =
				module.components['ProjectBoardFilterContainerComponent'];

			this._componentRef = this._viewContainerRef.createComponent<any>(
				ProjectBoardFilterContainerComponent,
				{ injector: ɵcreateInjector(module, this._injector) }
			);

			this.setComponentInputs();

			this._componentRef.instance.filterKanbanClicked
				.pipe(takeUntil(this._destroy$))
				.subscribe((filter: never) => this.filterKanbanClicked.emit(filter));

			this._componentRef.instance.onSortClicked
				.pipe(takeUntil(this._destroy$))
				.subscribe((sort: never) => this.onSortClicked.emit(sort));
			this._componentRef.instance.onNewViewCreated
				.pipe(takeUntil(this._destroy$))
				.subscribe(() => this.onNewViewCreated.emit());
			this._componentRef.instance.onViewRestored
				.pipe(takeUntil(this._destroy$))
				.subscribe(() => this.onViewRestored.emit());

			this._componentRef.changeDetectorRef.detectChanges();
		});
	}

	private setComponentInputs() {
		if (!this._componentRef) {
			return;
		}
		this._componentRef.setInput('projectId', this.projectId);
		this._componentRef.setInput('viewMode', this.viewMode);
		this._componentRef.setInput('projectMembers', this.projectMembers);
		this._componentRef.setInput('viewFilter', this.viewFilter);
		this._componentRef.setInput('canEditSprint', this.canEditSprint);
		this._componentRef.setInput('canAddSprint', this.canAddSprint);
		this._componentRef.setInput('allowManagerSprint', this.allowManagerSprint);
		this._componentRef.setInput('hasJoiningProjectRequest', this.hasJoiningProjectRequest);
		this._componentRef.setInput('filterMemberEnabled', this.filterMemberEnabled);
		this._componentRef.setInput('canViewGenerateSetting', this.canViewGenerateSetting);
		this._componentRef.setInput('canEditGenerateSetting', this.canEditGenerateSetting);
		this._componentRef.setInput('showProjectViewMode', this.showProjectViewMode);
		this._componentRef.setInput('sortEnabled', this.sortEnabled);
		this._componentRef.setInput('viewSorts', this.viewSorts);
	}
}
