import { REMOTE_HOSTED_CONFIGURATIONS, REMOTE_LOCAL_CONFIGURATIONS } from './configurations';
import { ServiceIdentity } from './models/remote-identity';

export function getConfiguration(serviceName: ServiceIdentity) {
	const configurations = window.location.host.startsWith('localhost')
		? REMOTE_LOCAL_CONFIGURATIONS
		: REMOTE_HOSTED_CONFIGURATIONS;

	const serviceConfiguration = configurations[serviceName];
	if (!serviceConfiguration) {
		console.log('Remote service configuration not found');
	}

	return serviceConfiguration;
}
