import { ServiceConfiguration } from '../models/remote-service-configuration';
import {
	ADMIN_CONSOLE_MODULES_IDENTITIES,
	CRM_MODULES_IDENTITIES,
	CUSTOM_APP_MODULES_IDENTITIES,
	GUIDANCE_CENTER_MODULES_IDENTITIES,
	HOME_MODULES_IDENTITIES,
	MESSENGER_MODULES_IDENTITIES,
	ANALYTICS_HUB_MODULES_IDENTITIES,
	WORK_MODULES_IDENTITIES,
} from '../models/remote-identity';

const remoteServerUrl = window.location.origin;

export const REMOTE_HOSTED_CONFIGURATIONS: {
	[key: string]: ServiceConfiguration;
} = {
	'crm': {
		entry: `${remoteServerUrl}/remotes/cleeksy-crm/crmRemote.js`,
		exposes: [...CRM_MODULES_IDENTITIES],
	},
	'work': {
		entry: `${remoteServerUrl}/remotes/cleeksy-work/workRemote.js`,
		exposes: [...WORK_MODULES_IDENTITIES],
	},
	'workspace': {
		entry: `${remoteServerUrl}/remotes/cleeksy-home/homeRemote.js`,
		exposes: [...HOME_MODULES_IDENTITIES],
	},
	'customApp': {
		entry: `${remoteServerUrl}/remotes/cleeksy-custom-app/customAppRemote.js`,
		exposes: [...CUSTOM_APP_MODULES_IDENTITIES],
	},
	'adminConsole': {
		entry: `${remoteServerUrl}/remotes/cleeksy-admin-console/adminConsoleRemote.js`,
		exposes: [...ADMIN_CONSOLE_MODULES_IDENTITIES],
	},
	'helpCenter': {
		entry: `${remoteServerUrl}/remotes/cleeksy-help-center/helpCenterRemote.js`,
		exposes: [...GUIDANCE_CENTER_MODULES_IDENTITIES],
	},
	'messenger': {
		entry: `${remoteServerUrl}/remotes/cleeksy-messenger/messengerRemote.js`,
		exposes: [...MESSENGER_MODULES_IDENTITIES],
	},
	'analyticsHub': {
		entry: `${remoteServerUrl}/remotes/cleeksy-analytics-hub/analyticsHubRemote.js`,
		exposes: [...ANALYTICS_HUB_MODULES_IDENTITIES],
	},
};
