import {
	ComponentRef,
	Directive,
	EventEmitter,
	Injector,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewContainerRef,
	ɵcreateInjector,
} from '@angular/core';

import { Subject, takeUntil } from 'rxjs';

import { loadModuleAsync } from '../../remote-app-loader';

@Directive({ selector: '[projectSettingComponentLoader]', standalone: true })
export class ProjectSettingLoaderDirective implements OnInit, OnChanges, OnDestroy {
	@Input() visible: boolean;
	@Input() selectedTabType: any;
	@Input() hideBackBtn: boolean;
	@Input() projectId: number;
	@Input() canEditGenerateSetting: boolean;
	@Input() customFormData: { formId: number; elementTypeId: number | null } | null;

	@Output() visibleChange = new EventEmitter<boolean>();

	private _componentRef: ComponentRef<any>;

	private _destroy$ = new Subject<void>();

	constructor(
		private _injector: Injector,
		private _viewContainerRef: ViewContainerRef
	) {}

	ngOnInit(): void {
		void this.initComponent();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (
			changes &&
			Object.keys(changes).length > 0 &&
			Object.keys(changes).every(key => !changes[key].firstChange)
		) {
			this.setComponentInputs();
		}
	}

	ngOnDestroy(): void {
		this._destroy$.next();
	}

	private async initComponent() {
		await loadModuleAsync('work', 'WorkRemoteProjectSettingModule').then(module => {
			const KanbanSettingPopupContainerComponent =
				module.components['KanbanSettingPopupContainerComponent'];

			this._componentRef = this._viewContainerRef.createComponent<any>(
				KanbanSettingPopupContainerComponent,
				{ injector: ɵcreateInjector(module, this._injector) }
			);

			this.setComponentInputs();

			this._componentRef.instance.visibleChange
				.pipe(takeUntil(this._destroy$))
				.subscribe((visible: never) => this.visibleChange.emit(visible));

			this._componentRef.changeDetectorRef.markForCheck();
		});
	}

	private setComponentInputs() {
		if (!this._componentRef) {
			return;
		}
		this._componentRef.setInput('projectId', this.projectId);
		this._componentRef.setInput('visible', this.visible);
		this._componentRef.setInput('selectedTabType', this.selectedTabType);
		this._componentRef.setInput('hideBackBtn', this.hideBackBtn);
		this._componentRef.setInput('canEditGenerateSetting', this.canEditGenerateSetting);
		this._componentRef.setInput('customFormData', this.customFormData);
	}
}
