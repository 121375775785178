export * from './help-center/help-center-popover.loader';

export * from './work/work-kanban-view.loader';
export * from './work/project-board-filter.loader';
export * from './work/project-view-setting.loader';
export * from './work/work-list-view.loader';
export * from './work/task-detail-panel.loader';
export * from './work/project-setting.loader';
export * from './work/project-activity-popover.loader';
export * from './work/project-activity-container.loader';
export * from './work/work-calendar-view.loader';
export * from './work/workflow-builder.loader';
export * from './work/project-sharing-setting.loader';

export * from './custom-app/custom-app-form-submit.loader';
export * from './custom-app/custom-app-record-detail.loader';
export * from './custom-app/custom-app-form-builder.loader';
export * from './custom-app/custom-app-record-appearance.loader';
export * from './custom-app/custom-app-field-management.loader';
export * from './custom-app/custom-app-data-source.loader';
export * from './custom-app/custom-app-record-detail-panel.loader';

export * from './crm/crm-lead-detail-form.loader';
export * from './crm/crm-deal-detail-form.loader';
export * from './crm/crm-post-detail-form.loader';

export * from './crm/crm-lead-creation-popup.loader';
export * from './crm/crm-deal-creation-popup.loader';
export * from './crm/crm-topic-detail-panel.loader';

export * from './crm/crm-deal-grid.loader';
export * from './crm/crm-lead-grid.loader';
export * from './crm/crm-topic-grid.loader';

export * from './crm/crm-community-setting-popup.loader';
export * from './crm/crm-setting-popup.loader';
export * from './crm/crm-product-setting-popup.loader';
