import {
	ComponentRef,
	Directive,
	EventEmitter,
	Injector,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewContainerRef,
} from '@angular/core';

import { Subject, takeUntil } from 'rxjs';

import { loadModuleAsync } from '../../remote-app-loader';

export interface ICustomAppFormBuilderContainerComponent {
	canEdit: boolean;
	hideBackBtn: boolean;
	formData: unknown;
	isFormDirty: () => boolean;

	onSubmitted: EventEmitter<void>;
	onCancel: EventEmitter<void>;
	onConfirmCanceled: EventEmitter<void>;
}

@Directive({
	standalone: true,
	selector: '[customAppFormBuilderLoaderDirective]',
	exportAs: 'FormBuilderLoaderDirective',
})
export class CustomAppFormBuilderLoaderDirective
	implements OnInit, OnChanges, OnDestroy, ICustomAppFormBuilderContainerComponent
{
	@Input() canEdit: boolean;
	@Input() hideBackBtn: boolean;
	@Input() formData: unknown;

	@Output() onSubmitted = new EventEmitter<void>();
	@Output() onCancel = new EventEmitter<void>();
	@Output() onConfirmCanceled = new EventEmitter<void>();

	private _componentRef: ComponentRef<ICustomAppFormBuilderContainerComponent>;
	private _destroy$ = new Subject<void>();

	constructor(
		private _injector: Injector,
		private _viewRef: ViewContainerRef
	) {}

	ngOnInit(): void {
		void this.initComponent();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (
			changes &&
			Object.keys(changes).length > 0 &&
			Object.keys(changes).every(key => !changes[key].firstChange)
		) {
			this.setComponentInputs();
		}
	}

	ngOnDestroy(): void {
		this._destroy$.next();
	}

	public isFormDirty() {
		return this._componentRef.instance.isFormDirty();
	}

	private async initComponent() {
		const component = await loadModuleAsync('customApp', 'CustomAppFormBuilderRemoteComponent');

		this._componentRef = this._viewRef.createComponent<ICustomAppFormBuilderContainerComponent>(
			component,
			{ injector: this._injector }
		);

		this.setComponentInputs();
		// Subscribe output events
		this._componentRef.instance.onSubmitted.pipe(takeUntil(this._destroy$)).subscribe(() => {
			this.onSubmitted.emit();
		});

		this._componentRef.instance.onCancel.pipe(takeUntil(this._destroy$)).subscribe(() => {
			this.onCancel.emit();
		});

		this._componentRef.instance.onConfirmCanceled
			.pipe(takeUntil(this._destroy$))
			.subscribe(() => {
				this.onConfirmCanceled.emit();
			});

		this._componentRef.changeDetectorRef.markForCheck();
	}

	private setComponentInputs() {
		if (!this._componentRef) {
			return;
		}
		// Set input data
		this._componentRef.setInput('canEdit', this.canEdit);
		this._componentRef.setInput('hideBackBtn', this.hideBackBtn);
		this._componentRef.setInput('formData', this.formData);
	}
}
