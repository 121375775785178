import {
	ComponentRef,
	Directive,
	Injector,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
	ViewContainerRef,
} from '@angular/core';

import { Subject } from 'rxjs';

import { loadModuleAsync } from '../../remote-app-loader';

export interface IDataSourceContainerComponent {
	selectedApp: any;
	projectId: number | undefined;
}

@Directive({
	standalone: true,
	selector: '[customAppDataSourceLoaderDirective]',
	exportAs: 'DataSourceLoaderDirective',
})
export class CustomAppDataSourceLoaderDirective
	implements OnInit, OnChanges, OnDestroy, IDataSourceContainerComponent
{
	@Input() selectedApp: any;
	@Input() projectId: number | undefined;

	private _componentRef: ComponentRef<IDataSourceContainerComponent>;

	private _destroy$ = new Subject<void>();

	constructor(
		private _injector: Injector,
		private _viewRef: ViewContainerRef
	) {}

	ngOnInit(): void {
		void this.initComponent();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (
			changes &&
			Object.keys(changes).length > 0 &&
			Object.keys(changes).every(key => !changes[key].firstChange)
		) {
			this.setComponentInputs();
		}
	}

	ngOnDestroy(): void {
		this._destroy$.next();
	}

	private async initComponent() {
		const component = await loadModuleAsync('customApp', 'CustomAppDataSourceRemoteComponent');

		this._componentRef = this._viewRef.createComponent<IDataSourceContainerComponent>(
			component,
			{ injector: this._injector }
		);

		this.setComponentInputs();

		this._componentRef.changeDetectorRef.markForCheck();
	}

	private setComponentInputs() {
		if (!this._componentRef) {
			return;
		}
		// Set input data
		this._componentRef.setInput('selectedApp', this.selectedApp);
		this._componentRef.setInput('projectId', this.projectId);
	}
}
