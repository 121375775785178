import { ServiceConfiguration } from '../models/remote-service-configuration';
import {
	ADMIN_CONSOLE_MODULES_IDENTITIES,
	ANALYTICS_HUB_MODULES_IDENTITIES,
	CRM_MODULES_IDENTITIES,
	CUSTOM_APP_MODULES_IDENTITIES,
	GUIDANCE_CENTER_MODULES_IDENTITIES,
	HOME_MODULES_IDENTITIES,
	MESSENGER_MODULES_IDENTITIES,
	WORK_MODULES_IDENTITIES,
} from '../models/remote-identity';

const hostname = window.location.hostname;
const protocol = window.location.protocol;

export const REMOTE_LOCAL_CONFIGURATIONS: {
	[key: string]: ServiceConfiguration;
} = {
	'crm': {
		entry: `${protocol}//${hostname}:4201/crmRemote.js`,
		exposes: [...CRM_MODULES_IDENTITIES],
	},
	'work': {
		entry: `${protocol}//${hostname}:4202/workRemote.js`,
		exposes: [...WORK_MODULES_IDENTITIES],
	},
	'workspace': {
		entry: `${protocol}//${hostname}:4206/homeRemote.js`,
		exposes: [...HOME_MODULES_IDENTITIES],
	},
	'customApp': {
		entry: `${protocol}//${hostname}:4212/customAppRemote.js`,
		exposes: [...CUSTOM_APP_MODULES_IDENTITIES],
	},
	'adminConsole': {
		entry: `${protocol}//${hostname}:4211/adminConsoleRemote.js`,
		exposes: [...ADMIN_CONSOLE_MODULES_IDENTITIES],
	},
	'helpCenter': {
		entry: `${protocol}//${hostname}:4213/helpCenterRemote.js`,
		exposes: [...GUIDANCE_CENTER_MODULES_IDENTITIES],
	},
	'messenger': {
		entry: `${protocol}//${hostname}:4214/messengerRemote.js`,
		exposes: [...MESSENGER_MODULES_IDENTITIES],
	},
	'analyticsHub': {
		entry: `${protocol}//${hostname}:4215/analyticsHubRemote.js`,
		exposes: [...ANALYTICS_HUB_MODULES_IDENTITIES],
	},
};
