import {
	ComponentRef,
	Directive,
	EventEmitter,
	Injector,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewContainerRef,
	ɵcreateInjector,
} from '@angular/core';

import { Subject, takeUntil } from 'rxjs';

import { loadModuleAsync } from '../../remote-app-loader';

@Directive({ selector: '[projectActivityPopoverComponentLoader]', standalone: true })
export class ProjectActivityPopoverLoaderDirective implements OnInit, OnChanges, OnDestroy {
	@Input() visible: boolean;
	@Output() visibleChange = new EventEmitter<boolean>();

	@Input() target: Element | string;
	@Input() subscriptionAuditLogType: number;

	private _componentRef: ComponentRef<any>;
	private _destroy$ = new Subject<void>();

	constructor(
		private _injector: Injector,
		private _viewContainerRef: ViewContainerRef
	) {}

	ngOnInit(): void {
		void this.initComponent();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (
			changes &&
			Object.keys(changes).length > 0 &&
			Object.keys(changes).every(key => !changes[key].firstChange)
		) {
			this.setComponentInputs();
		}
	}

	ngOnDestroy(): void {
		this._destroy$.next();
	}

	private async initComponent() {
		await loadModuleAsync('work', 'WorkRemoteProjectActivityModule').then(module => {
			const ProjectActivityPopoverContainerComponent =
				module.components['ProjectActivityPopoverContainerComponent'];

			this._componentRef = this._viewContainerRef.createComponent<any>(
				ProjectActivityPopoverContainerComponent,
				{ injector: ɵcreateInjector(module, this._injector) }
			);

			this.setComponentInputs();

			const component = this._componentRef.instance;
			component.visibleChange
				.pipe(takeUntil(this._destroy$))
				.subscribe((visible: never) => this.visibleChange.emit(visible));

			this._componentRef.changeDetectorRef.detectChanges();
		});
	}

	private setComponentInputs() {
		if (!this._componentRef) {
			return;
		}
		this._componentRef.setInput('visible', this.visible);
		this._componentRef.setInput('target', this.target);
		this._componentRef.setInput('subscriptionAuditLogType', this.subscriptionAuditLogType);
	}
}
