import {
	ComponentRef,
	Directive,
	EventEmitter,
	Injector,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewContainerRef,
	ɵcreateInjector,
} from '@angular/core';

import { Subject, takeUntil } from 'rxjs';

import { loadModuleAsync } from '../../remote-app-loader';

@Directive({ selector: '[workListViewComponentLoader]', standalone: true })
export class WorkListViewLoaderDirective implements OnInit, OnChanges, OnDestroy {
	@Input() filter: any;
	@Input() sorts: any[] = [];
	@Input() isShowInMyTaskWorkspace = false;
	@Input() isShowInFollowingTabWorkspace = false;
	@Input() isBubblingTaskClickEvent = false;

	@Output() onRequestTaskOpened = new EventEmitter<any>();

	private _componentRef: ComponentRef<any>;
	private _destroy$ = new Subject<void>();

	constructor(
		private _injector: Injector,
		private _viewContainerRef: ViewContainerRef
	) {}

	ngOnInit(): void {
		void this.initComponent();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (
			changes &&
			Object.keys(changes).length > 0 &&
			Object.keys(changes).every(key => !changes[key].firstChange)
		) {
			this.setComponentInputs();
		}
	}

	ngOnDestroy(): void {
		this._destroy$.next();
	}

	private async initComponent() {
		await loadModuleAsync('work', 'WorkRemoteProjectListViewModule').then(module => {
			const ProjectListContainerComponent =
				module.components['CleeksyProjectListContainerComponent'];

			this._componentRef = this._viewContainerRef.createComponent<any>(
				ProjectListContainerComponent,
				{ injector: ɵcreateInjector(module, this._injector) }
			);

			this.setComponentInputs();

			this._componentRef.instance.onRequestTaskOpened
				.pipe(takeUntil(this._destroy$))
				.subscribe((task: never) => this.onRequestTaskOpened.emit(task));

			this._componentRef.changeDetectorRef.detectChanges();
		});
	}

	private setComponentInputs() {
		if (!this._componentRef) {
			return;
		}
		this._componentRef.setInput('filter', this.filter);
		this._componentRef.setInput('sorts', this.sorts);
		this._componentRef.setInput('isShowInMyTaskWorkspace', this.isShowInMyTaskWorkspace);
		this._componentRef.setInput(
			'isShowInFollowingTabWorkspace',
			this.isShowInFollowingTabWorkspace
		);
		this._componentRef.setInput('isBubblingTaskClickEvent', this.isBubblingTaskClickEvent);
	}
}
